/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'

import { Skeleton, message, Spin } from 'antd'
import MenuComponent from '../Component/Menu'

import FilterImg from '../Agix/FilterImg'

import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {
    const [active, setActive] = useState('Explore')

    const [menuList, setMenuList] = useState([{
        name: 'Product',
        url: 'https://www.etnalabs.co/products'
    }, {
        name: 'Deepdive',
        url: 'https://www.etnalabs.co/research'
    }, {
        name: 'Contact Us',
        url: 'https://www.etnalabs.co/contact'
    }]);

    return (
        <div className="agix-content" >
            <div className="agix-temp0">
                <div className="agix-top-menu">
                    <MenuComponent isDark={true} menuList={menuList} />
                </div>
                <div className="agix-temp01" style={{ color: "#784AFF" }}>Products</div>
                <div className="agix-temp02">Building Up a Benchmark for the Al Revolution</div>
            </div>

            <div className="agix-content1"  >

                <div className="agix-temp1">Etna Capital provides sub-advisory services to KraneShares's pioneering Artificial Intelligence & Technology ETF.</div>
                <div className="agix-temp2">
                    <span className="agix-temp21">The Global Artificial Intelligence & Technology ETF: </span>
                    As a distinctive investment vehicle for capturing global opportunities across the AGI value chain, the ETF primarily holds publicly listed global AI and technology companies covered by the Solactive Etna Artificial General Intelligence Index. The Index is designed to capture the performance of companies engaged in developing and applying artificial intelligence technologies. Beyond public companies, it also can allocate to private companies that are not included in its underlying index.
                </div>
                {/* <div className="agix-temp3">
                    AGlX primarily holds publicly listed global Al and technology companies and can allocate to privatecompanies that are not included in its underlying index.
                </div> */}

                <img src={"/agix/ball.png"} className='agix-temp6' alt="" />



                <div onClick={() => {
                    window.open('https://kraneshares.com/agix/', '_blank')
                }} className="agix-temp4">
                    Learn More
                </div>

                <div className="agix-temp5">
                    <img src={'/agix/logo1.png'} className='agix-temp51' alt="logo" />
                    <div className="agix-temp52">Get Your Portfolio AGI-ready</div>

                    <div style={{ marginTop: "30px", fontFamily: "Roman" }}>

                        <p>
                            Etna is an investment adviser registered under the Investment Advisers Act of 1940, as amended. Additional information about Etna is also available on the SEC’s Investment Adviser Public Disclosure 
                            website <span onClick={() => {
                                window.open('https://adviserinfo.sec.gov/firm/summary/330644', '_blank')
                            }} style={{ color: "#784AFF", cursor: "pointer", textDecoration: "underline" }}>here</span>.
                        </p>

                        <p>

                            Carefully consider the Funds’ investment objectives, risk factors, charges and expenses before investing. This and additional information can be found in the Funds’ full and summary prospectus, which may be obtained by visiting: <span onClick={() => {
                                window.open('https://kraneshares.com/', '_blank')
                            }} style={{ color: "#784AFF", cursor: "pointer", textDecoration: "underline" }}>www.kraneshares.com</span>. Read the prospectus carefully before investing.

                        </p>

                        <p>
                            The opinions expressed herein are those of Etna Capital Management Ltd. (Etna) and are subject to change without notice. Material on this website is not financial advice or an offer to purchase or sell any product.
                        </p>
                        <p>
                            Investing involves risk, including possible loss of principal. There can be no assurance that a Fund will achieve its stated objectives. Indices are unmanaged and do not include the effect of fees. One cannot invest directly in an index.
                        </p>


                        <p>
                            This information should not be relied upon as research, investment advice, or a recommendation regarding any products, strategies, or any security in particular. This material is strictly for illustrative, educational, or informational purposes and is subject to change. Certain content represents an assessment of the market environment at a specific time and is not intended to be a forecast of future events or a guarantee of future results; material is as of the dates noted and is subject to change without notice.
                        </p>

                        <p>
                            The Fund may invest in derivatives, which are often more volatile than other investments and may magnify the Fund’s gains or losses. A derivative (i.e., futures/forward contracts, swaps, and options) is a contract that derives its value from the performance of an underlying asset. The primary risk of derivatives is that changes in the asset’s market value and the derivative may not be proportionate, and some derivatives can have the potential for unlimited losses. Derivatives are also subject to liquidity and counterparty risk. The Fund is subject to liquidity risk, meaning that certain investments may become difficult to purchase or sell at a reasonable time and price. If a transaction for these securities is large, it may not be possible to initiate, which may cause the Fund to suffer losses. Counterparty risk is the risk of loss in the event that the counterparty to an agreement fails to make required payments or otherwise comply with the terms of the derivative.

                        </p>

                        <p>
                            AI-exposed companies face profitability challenges due to high research costs, competition, IP reliance, and regulatory risk. Product failures or safety concerns could be detrimental. Identifying AI companies accurately is complex. Tech firms face risks of product failure, obsolescence, regulatory impact, and uncertain profitability due to technological advancements and government policies. Certain tech investments may lack current profitability and future success is uncertain. The Fund is subject to non-U.S. issuers risk, which may be less liquid than investments in U.S. issuers, may have less governmental regulation and oversight, are typically subject to different investor protection standards than U.S. issuers, and the economic instability of the non-U.S. countries. Fluctuations in currency of foreign countries may have an adverse effect to domestic currency values. The Fund may invest in Initial Public Offerings (IPOs). Securities issued in IPOs have no trading history, and information about the companies may be available for very limited periods. In addition, the prices of securities sold in IPOs may be highly volatile. In addition, as the Fund increases in size, the impact of IPOs on the Fund’s performance will generally decrease.
                        </p>

                        <p>
                            Large capitalization companies may struggle to adapt fast, impacting their growth compared to smaller firms, especially in expansive times. This could result in lower stock returns than investing in smaller and mid-sized companies. In addition to the normal risks associated with investing, investments in smaller companies typically exhibit higher volatility. The Fund is new and does not yet have a significant number of shares outstanding. If the Fund does not grow in size, it will be at greater risk than larger funds of wider bid-ask spreads for its shares, trading at a greater premium or discount to NAV, liquidation and/or a trading halt. Narrowly focused investments typically exhibit higher volatility. The Fund’s assets are expected to be concentrated in a sector, industry, market, or group of concentrations to the extent that the Underlying Index has such concentrations. The securities or futures in that concentration could react similarly to market developments. Thus, the Fund is subject to loss due to adverse occurrences that affect that concentration.
                        </p>

                        <p>
                            A large number of shares of the Fund are held by a single shareholder or a small group of shareholders. Redemptions from these shareholders can harm Fund performance, especially in declining markets, leading to forced sales at disadvantageous prices, increased costs, and adverse tax effects for remaining shareholders. AGIX is non-diversified.
                        </p>

                        <p>
                            ETF shares are bought and sold on an exchange at market price (not NAV) and are not individually redeemed from the Fund. However, shares may be redeemed at NAV directly by certain authorized broker-dealers (Authorized Participants) in very large creation/redemption units. The returns shown do not represent the returns you would receive if you traded shares at other times. Shares may trade at a premium or discount to their NAV in the secondary market. Brokerage commissions will reduce returns. Beginning 12/23/2020, market price returns are based on the official closing price of an ETF share or, if the official closing price isn't available, the midpoint between the national best bid and national best offer ("NBBO") as of the time the ETF calculates the current NAV per share. Prior to that date, market price returns were based on the midpoint between the Bid and Ask price. NAVs are calculated using prices as of 4:00 PM Eastern Time.
                        </p>

                        <p>
                            The KraneShares ETFs and KFA Funds ETFs are distributed by SEI Investments Distribution Company (SIDCO), 1 Freedom Valley Drive, Oaks, PA 19456, which is not affiliated with Krane Funds Advisors, LLC, the Investment Adviser for the Funds, or any sub-advisers for the Funds.
                        </p>

                    </div>
                </div>



            </div>

        </div>

    )
}

export default App
